import axios from 'axios';
import moment from "moment";

export const API_URL = process.env.REACT_APP_API_URL + "api/public/";
export const IMG_URL = process.env.REACT_APP_IMG_URL + "uploads/artbanne/";


let storedStayDatas = {
    nbPersons: undefined,
    stayDates: {
        startDate: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
        endDate: moment(new Date()).add(3, 'days').format('YYYY-MM-DD')
    }
};

let storedBlockedDates = [];
let dataLoaded = false;

export const Api = {
    saveStayDatas: function (data) {
        storedStayDatas = data;
    },

    getStayDatas: function () {
        return storedStayDatas;
    },

    storeBlockedDates: function (data) {
        storedBlockedDates = data;
        dataLoaded = true; // Set loaded to true once data is stored
    },

    getBlockedDatesStored: function () {
        return storedBlockedDates;
    },

    isDataLoaded: function () {
        return dataLoaded;
    },

    getBlockedDates: async function () {
        try {
            return await axios.get(API_URL + 'calendar/blockedDates');
        } catch (error) {
            console.log(error);
            return error;
        }
    },

    sendMessageContact: async function (data) {
        try {
            return await axios.post(API_URL + 'contact/', { data });

        } catch (error) {
            console.log(error);
            return error;
        }
    },

    sendMessageContactCommercial: async function (data) {
        try {
            return await axios.post(API_URL + 'contact/commercial', { data });

        } catch (error) {
            console.log(error);
            return error;
        }
    },

    getPrices: async function () {
        try {
            return await axios.get(API_URL + 'prices/');

        } catch (error) {
            console.log(error);
            return error;
        }
    },

    getServices: async function () {
        try {
            return await axios.get(API_URL + 'service/');

        } catch (error) {
            console.log(error);
            return error;
        }
    },

    getQuotation: async function (id) {
        try {
            return await axios.get(API_URL + 'quotation/getQuotation/' + id);

        } catch (error) {
            console.log(error);
            return error;
        }
    },

    sendQuotation: async function (quotation) {
        try {
            return await axios.post(API_URL + 'quotation/sendQuotation/', { quotation });

        } catch (error) {
            console.log(error);
            return error;
        }
    },

    getPDFQuotation: async function (quotation) {
        try {
            return await axios.get(API_URL + 'quotation/getPDFQuotation/' + quotation._id, { responseType: 'blob', timeout: 30000 });

        } catch (error) {
            console.log(error);
            return error;
        }
    },

    visualizeQuotation: async function (quotation) {
        try {
            const response = await this.getPDFQuotation(quotation);
            const fileURL = window.URL.createObjectURL(response.data);
            window.open(fileURL);

        } catch (error) {
            console.log(error);
            return error;
        }
    },

    downloadQuotation: async function (quotation) {
        try {
            const response = await this.getPDFQuotation(quotation);
            this.downloadBlob(response.data, "Devis.pdf");

        } catch (error) {
            console.log(error);
            return error;
        }
    },

    downloadBlob: function (blob, fileName) {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    },

    changeStatusQuotation: async function (quotationID, status) {
        try {
            return await axios.post(API_URL + 'quotation/changeStatusQuotation/', { quotationID, status });

        } catch (error) {
            console.log(error);
            return error;
        }
    },

    sendChangeRequest: async function (quotationID, changeRequest) {
        try {
            return await axios.post(API_URL + 'quotation/sendChangeRequest/', { quotationID, changeRequest });

        } catch (error) {
            console.log(error);
            return error;
        }
    },

    saveQuotation: async function (quotation) {
        try {
            return await axios.post(API_URL + 'quotation/saveQuotation/', { quotation });

        } catch (error) {
            console.log(error);
            return error;
        }
    }
}

export default Api;