import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import imageHeaderBanner from "./../../assets/images/events/backgroundEvents.png";

// import Button from "../../components/Buttons/button";
import BackgroundImage from "../../components/Banner/background-header";
import H1 from "../../components/Titles/h1";
import { Helmet } from "react-helmet";
import DescriptionSplit from "../../components/Description/description-split";
import H3 from "../../components/Titles/h3";
import H2 from "../../components/Titles/h2";

import imageBottles from './../../assets/images/events/bottles.png';

import iconClim from './../../assets/icons/events/iconClim.svg';
import iconDrink from './../../assets/icons/events/iconDrink.svg';
import iconNotepads from './../../assets/icons/events/iconNotepads.svg';
import iconPaperBoard from './../../assets/icons/events/iconPaperBoard.svg';
import iconVideoProjectors from './../../assets/icons/events/iconVideoProjectors.svg';
import iconWifi from './../../assets/icons/events/iconWifi.svg';
import CarouselImages from "../../components/Carousel/carousel-images";
import useIntersectionObserver from "../../utils/hooks/intersection-observer";

import icon8 from './../../assets/icons/events/8.svg';
import icon10 from './../../assets/icons/events/10.svg';
import icon12 from './../../assets/icons/events/12.svg';
import icon17 from './../../assets/icons/events/17.svg';

import iconPool from './../../assets/icons/events/iconPool.svg';
import iconWine from './../../assets/icons/events/iconWine.svg';
import H4 from "../../components/Titles/h4";
import UnorderedList from "../../components/List/unordered-list";

import Icon from "../../components/Images/icon";
import iconMail from "../../assets/icons/contact/mail.svg";
import iconPhone from "../../assets/icons/contact/phone.svg";

import Button from "../../components/Buttons/button";
import PersonnalInfoForm from "../../components/Forms/personnal-info-form";
import Validator from "../../services/validator.service";
import Textarea from "../../components/Inputs/textarea";
import Api from "../../services/api.service";
import WaveComponent from "../../components/Style/wave";
import Helper from "../../services/helper.service";

import * as _ from "lodash";
import EventInfoForm from "../../components/Forms/event-info-form";

export default function Events() {
    const { t, i18n } = useTranslation();

    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const iconsRef = useRef(null);

    useIntersectionObserver(
        [titleRef, descriptionRef, iconsRef],
        ["animate-fade-in-right", "animate-fade-in-up", "animate-fade-in-up"]
    );

    const icons = [
        { src: iconVideoProjectors, id: 'videoProjectors' },
        { src: iconPaperBoard, id: 'paperboard' },
        { src: iconClim, id: 'clim' },
        { src: iconNotepads, id: 'notepads' },
        { src: iconWifi, id: 'wifi' },
        { src: iconDrink, id: 'drink' }
    ];

    const titles = t('events.sectionCapacity.data.titles', { returnObjects: true });
    const lines = t('events.sectionCapacity.data.lines', { returnObjects: true });

    const dispositionIconsMap = {
        8: icon8,
        10: icon10,
        12: icon12,
        17: icon17
    };

    const dataUniqueExperience = t('events.sectionUniqueExperience.data', { returnObjects: true });

    const dataUnite = t('events.sectionUnite.data', { returnObjects: true });
    const uniteIconsMap = {
        "pool": iconPool,
        "wine": iconWine
    };

    const isMobile = window.innerWidth < 768;

    const [personnalInfoFormData, setPersonnalInfoFormData] = useState({ lastname: "", firstname: "", email: "", phone: "" });
    const updatePersonnalInfoFormData = (newData) => { setPersonnalInfoFormData(newData) };
    const [errorsPersonnalInfo, setErrorsPersonnalInfo] = useState({});

    const getInitialStayDates = () => {
        const stayDatas = Api.getStayDatas();
        const storedStayDates = localStorage.getItem('stayDates');

        return storedStayDates ? JSON.parse(storedStayDates) : stayDatas.stayDates || {
            startDate: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
            endDate: moment(new Date()).add(3, 'days').format('YYYY-MM-DD')
        };
    };

    const [eventInfoFormData, setEventInfoFormData] = useState({ companyName: "", event: "", nbPersons: "", stayDates: getInitialStayDates() });
    console.log(eventInfoFormData);

    const updateEventInfoFormData = (newData) => { setEventInfoFormData(newData) };
    const [errorsEventInfo, setErrorsEventInfo] = useState({});

    const [message, setMessage] = useState("");
    const handleMessageChange = (message) => { setMessage(message.target.value) };
    const [errorMessage, setErrorsMessage] = useState({});

    async function handleSendButtonClick() {
        const tmpErrorsPersonnalInfo = Validator.validatePersonnalInfoFormData(personnalInfoFormData, t);
        setErrorsPersonnalInfo(tmpErrorsPersonnalInfo);

        const tmpErrorsEventInfo = Validator.validateEventInfoFormData(eventInfoFormData, t);
        setErrorsEventInfo(tmpErrorsEventInfo);

        const tmpErrorMessage = Validator.validateMessageContactFormData(message, t);
        setErrorsMessage(tmpErrorMessage);

        console.log(
            tmpErrorsPersonnalInfo,
            tmpErrorsEventInfo,
            tmpErrorMessage
        );


        if (Object.keys(tmpErrorsPersonnalInfo).length === 0 &&
            Object.keys(tmpErrorsEventInfo).length === 0 &&
            Object.keys(tmpErrorMessage).length === 0) {

            await Api.sendMessageContactCommercial({ ...personnalInfoFormData, ...eventInfoFormData, message: message, language: i18n.language }).then((response) => {
                Helper.handleApiResponse(response, t('responses.messageContactSent'), t('responses.messageContactError'));
                if (_.get(response, 'data.status')) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'eventsFormContactSubmissionSuccess',
                        formType: 'events'
                    });
                }
            });
        }
    }

    return (
        <div>
            <Helmet>
                <title>{t('events.page')}</title>
                <meta name="description" content={t('events.description')} />
                <meta name="keywords" content={t('events.keywords')} />
            </Helmet>

            <div className="absolute top-0 w-full">
                <BackgroundImage image={imageHeaderBanner} alt={t('reservation.altBackground')} />
            </div>

            <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/4 top-1/2 w-4/5 md:w-3/5 text-center">
                <H1 title={t('events.title')} />

                <div className="mt-4 mb-6">
                    <DescriptionSplit description={t('events.subtitle')} text='center' />
                </div>
            </div>

            <div className="relative mt-[100vh] pb-12">
                <div className="mt-12 lg:mt-40 flex flex-col lg:flex-row items-center">
                    <div className="w-10/12 mr-[16.666%] lg:w-1/2 lg:mr-0">
                        <CarouselImages listOfImages={[{ src: imageBottles, alt: 'Bottles' }]} verticalAlign="left" horizontalAlign="bottom" />
                    </div>
                    <div className="lg:w-1/2 p-6 lg:pr-24 lg:py-0 lg:pl-12">
                        <div ref={titleRef} className="opacity-0 mb-6">
                            <H2 title={t('events.sectionEquipments.title')} />
                        </div>
                        <div ref={descriptionRef} className="opacity-0 mb-6">
                            <DescriptionSplit description={t('events.sectionEquipments.description')} />
                        </div>

                        <div ref={iconsRef} className="opacity-0 grid grid-cols-2 gap-8 lg:gap-12 items-center justify-center md:justify-start">
                            {icons.map((icon, index) => (
                                <div key={index} className="flex flex-col align-center items-center text-center">
                                    <div className="flex items-left justify-left gap-4">
                                        <img src={icon.src} alt={t(`events.sectionEquipments.data.${icon.id}.alt`)} className="icon" />
                                    </div>
                                    <DescriptionSplit description={t(`events.sectionEquipments.data.${icon.id}.text`)} text="center" className='pt-2' />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="mt-8 md:mt-16 w-full">
                    <div className="p-6 md:p-0 opacity-90">
                        <div className="text-center p-4 md:p-12">
                            <H2 title={t('events.sectionCapacity.title')} theme="dark" />
                        </div>

                        <div className="mb-6">
                            <DescriptionSplit description={t('events.sectionCapacity.description')} text={isMobile ? "justify" : "center"} className="max-w-5xl m-auto" />
                        </div>
                    </div>
                    <div className="overflow-x-auto flex justify-center p-1">
                        <table className="w-full lg:w-4/5 overflow-x-auto border border-main table-fixed">
                            <thead>
                                <tr>
                                    {titles.map((title, index) => (
                                        <th key={index} className="border border-main px-4 py-2 text-left font-bold w-1/3">{title}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {lines.map((line, index) => (
                                    <tr key={index} className="border-t-0 border-main leading-10 border-b border-main border-opacity-60">
                                        <td className="border-main px-4 py-2 flex items-center gap-2 opacity-60">
                                            {line[0]}
                                        </td>
                                        <td className="border-l border-r border-main px-4 py-2 opacity-60">
                                            {line[1]}
                                        </td>
                                        <td className="border-l border-r border-main px-4 py-2">
                                            <div className="flex flex-wrap gap-6">
                                                {line[2].map((disposition, idx) => (
                                                    <img key={idx} src={dispositionIconsMap[disposition]} alt={`Disposition ${disposition}`} className="w-6 h-6" />
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>

                <div className="mt-4 md:mt-16 w-full">
                    <div className="p-6 md:p-0 opacity-90">
                        <div className="text-center p-4 md:p-8">
                            <H2 title={t('events.sectionUniqueExperience.title')} theme="dark" />
                        </div>

                        <div className="mb-8 md:mb-16">
                            <DescriptionSplit description={t('events.sectionUniqueExperience.description')} text={isMobile ? "justify" : "center"} className="max-w-5xl m-auto" />
                        </div>
                    </div>

                    <div className="p-4 w-full lg:w-4/5 m-auto grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-4 items-start">
                        {dataUniqueExperience.map((line, index) => (
                            <div className="flex flex-col justify-between gap-6 mb-6" key={index}>
                                <H3 title={line.title} className="colored-underline text-black text-2xl leading-relaxed" />
                                {/* <div className="mb-6"> */}
                                <DescriptionSplit description={line.description} className="max-w-5xl m-auto" />
                                {/* </div> */}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-0 md:mt-16 w-full">
                    <div className="p-6 md:p-0 opacity-90">
                        <div className="text-center p-4 md:p-8">
                            <H2 title={t('events.sectionUnite.title')} theme="dark" />
                        </div>

                        <div className="mb-8">
                            <DescriptionSplit description={t('events.sectionUnite.description')} text="center" className="max-w-5xl m-auto" />
                        </div>

                        <div className="w-full md:w-fit flex justify-around flex-col md:flex-row gap-8 md:gap-24 my-0 mx-auto">
                            {dataUnite.map((data, index) => (
                                <div className="p-4 xl:p-8 xl:pb-4 rounded-3xl shadow-lg text-center w-full md:w-fit my-0 mx-auto flex flex-col items-center gap-4 flex-grow">
                                    <div key={index} className="flex gap-4 items-center">
                                        <img key={index} src={uniteIconsMap[data.icon]} alt={`Icone ${data.icon}`} className="w-6 h-6" />
                                        <H4 title={data.title} fontWeight="bold" />
                                    </div>

                                    <div className="text-left flex-grow">
                                        <UnorderedList items={data.points} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="relative m-auto my-8 md:my-32 bg-white p-4 md:p-8 rounded-3xl w-11/12 lg:w-3/4 shadow">
                    <div className="hidden md:block absolute top-0 right-0">
                        <WaveComponent />
                    </div>
                    <div className="xl:w-1/2 md:w-2/3 pr-8">
                        <H2 title={t('events.sectionContact.title')} className="text-2xl md:text-2xl lg:text-3xl" />
                        <DescriptionSplit description={t('events.sectionContact.titleDescription')} className="mt-4 mb-6" />
                    </div>
                    <div className="flex flex-col md:flex-row gap-4 md:gap-12">
                        <div className="w-full xl:w-1/2 md:w-2/3">
                            <PersonnalInfoForm customer={personnalInfoFormData} errors={errorsPersonnalInfo} updatePersonnalInfoFormData={updatePersonnalInfoFormData} />
                            <div className="mt-8">
                                <EventInfoForm eventData={eventInfoFormData} errors={errorsEventInfo} updateEventInfoFormData={updateEventInfoFormData} />
                            </div>

                            <div className="my-4 md:my-8">
                                <Textarea label={t('formInputs.message')} value={message} onChange={handleMessageChange} placeholder={t('formInputs.writeHere')} error={errorMessage} />
                            </div>
                            <div className="text-center md:text-left">
                                <Button text={t('inputs.send')} onClick={handleSendButtonClick} />
                            </div>
                        </div>
                        <div className="w-full xl:w-1/2 md:w-1/3">
                            <H3 title={t('events.sectionContact.contactDetails')} />
                            <DescriptionSplit description={t('events.sectionContact.contactDescription')} />
                            <div className="mt-8 mb-16 flex flex-wrap justify-center gap-4">
                                <a href="tel:+33387880930" className="flex-1">
                                    <div className="p-4 xl:p-8 rounded-3xl shadow-lg text-center w-fit m-auto flex flex-col items-center gap-4">
                                        <Icon icon={iconPhone} alt={t('events.sectionContact.altIconPhone')} />
                                        <span className="font-bold text-md whitespace-nowrap">
                                            + 33 (0)3 87 88 09 30
                                        </span>
                                    </div>
                                </a>
                                <a href="mailto:commercial@lartbanne.com" className="flex-1">
                                    <div className="p-4 xl:p-8 rounded-3xl shadow-lg text-center w-fit m-auto flex flex-col items-center gap-4">
                                        <Icon icon={iconMail} alt={t('events.sectionContact.altIconMail')} />
                                        <span className="font-bold text-md whitespace-nowrap">
                                            commercial@lartbanne.com
                                        </span>
                                    </div>
                                </a>
                            </div>
                            <H3 title={t('events.sectionContact.contactLocalisationTitle')} />
                            <DescriptionSplit description={t('events.sectionContact.contactLocalisationDescription')} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}