import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Helper = {
    handleApiResponse(response, textSuccess = "", textError = "") {
        if (response.data.status) {
            toast.success(textSuccess, {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error(textError, {
                autoClose: 3000,
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    },

    toast(text, type) {
        const options = {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
        };

        if (type === 'success') {
            toast.success(text, options);

        } else if (type === 'error') {
            toast.error(text, options);

        } else {
            toast.info(text, options);
        }
    },

    getStepQuotation(quotation) {
        switch (quotation.mainInfos.status) {
            case 'pending':
            default:
                return "step1";

            case 'accepted':
                return "step2";

            case 'paiement':
                return "step3";

            case 'validated':
                return "validated";

            case 'modified':
                return "modified";

            case 'refused':
                return "refused";

            case 'paid':
                return "paid";
        }
    },

    setDataToQuotation(quotation, personnalInfoData, billingInfoData) {
        quotation.customer.lastname = personnalInfoData.lastname;
        quotation.customer.firstname = personnalInfoData.firstname;
        quotation.customer.email = personnalInfoData.email;
        quotation.customer.phone = personnalInfoData.phone;

        quotation.customer.companyName = personnalInfoData.companyName;
        quotation.customer.siret = personnalInfoData.siret;
        quotation.customer.VATNumber = personnalInfoData.VATNumber;
        quotation.address.country = billingInfoData.country;
        quotation.address.city = billingInfoData.city;
        quotation.address.address = billingInfoData.address;
        quotation.address.postalCode = billingInfoData.postalCode;
        quotation.address.address2 = billingInfoData.address2;

        return quotation;
    },

    setGuestDataToQuotation(quotation, customer, adultsGuests, childsGuests) {
        const guests = [customer, ...adultsGuests, ...childsGuests];

        quotation.customer.birthdate = customer.birthdate;
        quotation.guests = guests;

        return quotation;
    }
};

export default Helper;