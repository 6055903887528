import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import imageHeaderBanner from "./../../assets/images/global/imageHeaderBanner.png";

import DescriptionSplit from "../../components/Description/description-split";

import BackgroundImage from "../../components/Banner/background-header";
import H1 from "../../components/Titles/h1";
import H3 from "../../components/Titles/h3";
import H4 from "../../components/Titles/h4";
import H5 from "../../components/Titles/h5";

import { Helmet } from "react-helmet";
import Icon from "../../components/Images/icon";

import iconBreakfast from "../../assets/icons/services/breakfast.svg";
import iconConcierge from "../../assets/icons/services/concierge.svg";
import iconCleaning from "../../assets/icons/services/cleaning.svg";

import laundry from "../../assets/icons/services/laundry.svg";
import drinks from "../../assets/icons/services/drinks.svg";
import wifi from "../../assets/icons/services/wifi.svg";
import parking from "../../assets/icons/services/parking.svg";
import barbecue from "../../assets/icons/services/barbecue.svg";
import pool from "../../assets/icons/services/pool.svg";
import sport from "../../assets/icons/services/sport.svg";
import sauna from "../../assets/icons/services/sauna.svg";
import bedBaby from "../../assets/icons/services/bedBaby.svg";

import brunch from "../../assets/icons/services/brunch.svg";
import van from "../../assets/icons/services/van.svg";
import photo from "../../assets/icons/services/photo.svg";
import glass from "../../assets/icons/services/glass.svg";
import shopping from "../../assets/icons/services/shopping.svg";
import tasting from "../../assets/icons/services/tasting.svg";
import aesthetic from "../../assets/icons/services/aesthetic.svg";
import clothing from "../../assets/icons/services/clothing.svg";
import H2 from "../../components/Titles/h2";
import ActivityCard from "../../components/Card/activity-card";
import useIntersectionObserver from "../../utils/hooks/intersection-observer";

// import location from "../../assets/icons/services/location.svg";

export default function Services() {
    const { t } = useTranslation();

    const servicesIncluded = useMemo(() => [
        { icon: laundry, name: "laundry" },
        { icon: drinks, name: "drinks" },
        { icon: wifi, name: "wifi" },
        { icon: parking, name: "parking" },
        { icon: barbecue, name: "barbecue" },
        { icon: pool, name: "pools" },
        { icon: sport, name: "sport" },
        { icon: sauna, name: "sauna" },
        { icon: bedBaby, name: "bedBaby" }

    ], []);

    const servicesAdditionnal = useMemo(() => [
        { icon: brunch, name: "brunch" },
        { icon: van, name: "van" },
        { icon: photo, name: "photo" },
        { icon: glass, name: "glass" },
        { icon: shopping, name: "shopping" },
        { icon: tasting, name: "tasting" },
        { icon: aesthetic, name: "aesthetic" },
        { icon: van, name: "babySitting" },
        { icon: clothing, name: "clothing" }
    ], []);

    const serviceIncludedRefs = useRef(servicesIncluded.map(() => React.createRef()));
    useIntersectionObserver(serviceIncludedRefs.current, new Array(servicesIncluded.length).fill("animate-fade-in-up"), 150, 0.25);

    const serviceAdditionnalRefs = useRef(servicesAdditionnal.map(() => React.createRef()));
    useIntersectionObserver(serviceAdditionnalRefs.current, new Array(servicesAdditionnal.length).fill("animate-fade-in-up"), 150, 0.25);

    const activitiesData = t('activities.data', { returnObjects: true });
    const activitiesRefs = useRef(activitiesData.map(() => React.createRef()));
    useIntersectionObserver(activitiesRefs.current, new Array(activitiesData.length).fill("animate-fade-in-up"), 100, 0.25);

    return (
        <div>
            <Helmet>
                <title>{t('services.page')}</title>
                <meta name="description" content={t('services.description')} />
                <meta name="keywords" content={t('services.keywords')} />
            </Helmet>

            <div className="absolute top-0 w-full h-screen"> {/* Ensure it takes full height */}
                <BackgroundImage image={imageHeaderBanner} alt={t('reservation.altBackground')} />
            </div>


            <div className="mt-[50vh] relative md:absolute md:left-1/2 md:transform md:-translate-x-1/2 md:bottom-12 w-full md:w-4/5 text-center">
                <H1 title={t('services.title')} />

                <div className="flex items-center justify-center my-12">
                    <H3 title={t('services.subtitle')} className="colored-underline text-black text-2xl leading-relaxed" />
                </div>

                <div className="flex flex-col md:flex-row justify-between gap-6">
                    <div className="w-10/12 md:w-[30%] mx-auto bg-white p-4 pt-4 md:pt-6 flex flex-col items-center gap-4 rounded-3xl shadow-lg">
                        <Icon icon={iconBreakfast} alt={t('services.servicesBanner.breakfast.alt')} maxHeight="70px" maxWidth="100px" />
                        <H4 title={t('services.servicesBanner.breakfast.title')} theme="main" fontWeight="bold" />
                        <DescriptionSplit description={t('services.servicesBanner.breakfast.description')} className="md:text-sm" />
                    </div>

                    <div className="w-10/12 md:w-[30%] mx-auto bg-white p-2 pt-4 md:pt-6 md:p-4 flex flex-col items-center gap-4 rounded-3xl shadow-lg">
                        <Icon icon={iconConcierge} alt={t('services.servicesBanner.concierge.alt')} maxHeight="70px" maxWidth="100px" />
                        <H4 title={t('services.servicesBanner.concierge.title')} theme="main" fontWeight="bold" />
                        <DescriptionSplit description={t('services.servicesBanner.concierge.description')} className="md:text-sm" />
                    </div>

                    <div className="w-10/12 md:w-[30%] mx-auto bg-white p-2 pt-4 md:pt-6 md:p-4 flex flex-col items-center gap-4 rounded-3xl shadow-lg">
                        <Icon icon={iconCleaning} alt={t('services.servicesBanner.cleaning.alt')} maxHeight="70px" maxWidth="100px" />
                        <H4 title={t('services.servicesBanner.cleaning.title')} theme="main" fontWeight="bold" />
                        <DescriptionSplit description={t('services.servicesBanner.cleaning.description')} className="md:text-sm" />
                    </div>
                </div>
            </div>

            <div className="relative mt-12 md:mt-[100vh] w-11/12 md:w-4/5 m-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                    {servicesIncluded.map((service, index) => (
                        <div ref={serviceIncludedRefs.current[index]} key={service.name} className="opacity-0 p-4 rounded">
                            <div className="flex gap-4">
                                <Icon icon={service.icon} alt={t(`services.servicesIncluded.${service.name}.altIcon`)} />
                                <H5 title={t(`services.servicesIncluded.${service.name}.title`)} />
                            </div>
                            <DescriptionSplit description={t(`services.servicesIncluded.${service.name}.description`)} className="md:text-sm" />
                        </div>
                    ))}
                </div>

                <div className="text-center mx-auto mt-12 md:mt-24 mb-8 md:mb-12">
                    <H3 title={t('services.servicesAdditionnal.title')} className="colored-underline text-black text-2xl leading-relaxed" />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                    {servicesAdditionnal.map((service, index) => (
                        <div ref={serviceAdditionnalRefs.current[index]} key={service.name} className="opacity-0 p-4 rounded">
                            <div className="flex gap-4">
                                <Icon icon={service.icon} alt={t(`services.servicesAdditionnal.${service.name}.altIcon`)} />
                                <H5 title={t(`services.servicesAdditionnal.${service.name}.title`)} />
                            </div>
                            <DescriptionSplit description={t(`services.servicesAdditionnal.${service.name}.description`)} className="md:text-sm" />
                        </div>
                    ))}
                </div>

                <div className="mt-12 md:mt-24 mb-12 md:mb-24 text-center">
                    <H2 title={t('services.activities.title')} />
                    <div className="mt-2 mb-4 md:mb-8">
                        <DescriptionSplit description={t('services.activities.title')} text="center" />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 md:gap-12">
                        {activitiesData.map((activity, index) => (
                            <div ref={activitiesRefs.current[index]} key={index} className="opacity-0">
                                <ActivityCard activity={activity} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}