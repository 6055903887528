import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import BackgroundImage from '../../../../components/Banner/background-header';
import DateFieldInput from '../../../../components/Inputs/date-field';
import Select from '../../../../components/Inputs/select';
import Button from '../../../../components/Buttons/button';
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Api from '../../../../services/api.service';
import { useNavigate } from 'react-router-dom';

const HeaderBanner = ({ banner, stayDates, handleDateChange, nbPersons, handleSelectChangePersons }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [blockedDates, setBlockedDates] = useState([]);
    const [retryCount, setRetryCount] = useState(0);

    useEffect(() => {
        const fetchBlockedDates = () => {
            if (Api.isDataLoaded()) {
                const dates = Api.getBlockedDatesStored();
                setBlockedDates(dates);

            } else if (retryCount < 20) {
                setTimeout(fetchBlockedDates, 500);
                setRetryCount(retryCount + 1);

            } else {
                console.log("Failed to load data after 5 attempts.");
            }
        };

        fetchBlockedDates();
        return () => clearTimeout(fetchBlockedDates);

    }, [retryCount]);

    const nbPersonsOptions = () => {
        return Array.from({ length: 23 }, (_, index) => {
            if (index === 0) {
                return null; // Exclude the option with value 0
            }

            return {
                value: `${index}`,
                label: `${index} ${index > 1 ? `${t('formInputs.persons')}` : `${t('formInputs.person')}`}`,
            };
        }).filter(option => option !== null);
    };

    function navigateReservation() {
        Api.saveStayDatas({ nbPersons: nbPersons, stayDates: stayDates });
        navigate('/reservation');
    }

    const tenYearsLater = new Date(new Date().setFullYear(new Date().getFullYear() + 10));

    return (
        <div className="relative">
            <BackgroundImage image={banner} alt={t('home.sectionBanner.altBackground')} displayGradient={false} />
            <div className="absolute bottom-8 lg:bottom-24 left-1/2 transform -translate-x-1/2 w-10/12 lg:w-3/4 bg-white bg-opacity-80 rounded-xl flex flex-col items-center lg:flex-row justify-between lg:items-end gap-4 lg:gap-10 p-4 lg:p-6 z-50">
                <div className="w-full lg:flex-1 min-w-[250px]">
                    <DateFieldInput label='stayDate' value={stayDates} minDate={new Date()} maxDate={tenYearsLater} onChange={handleDateChange} displayLabel={true} singleDate={false} blockedDates={blockedDates} />
                </div>
                <div className="w-full lg:flex-1">
                    <Select label="nbPersons" name={t('formInputs.nbPersons')} options={nbPersonsOptions()} value={nbPersons} onChange={handleSelectChangePersons} displayLabel={true} iconDisplayed={faUser} error={null} />
                </div>
                <div className="w-full lg:flex-0 contents">
                    <Button text={t('home.sectionBanner.btn')} onClick={navigateReservation} className='mb-[2px]' />
                </div>
            </div>
        </div>
    );
};

export default HeaderBanner;
