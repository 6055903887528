import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import FullWidthImage from '../../../../components/Images/image-full-width';
import H2 from '../../../../components/Titles/h2';
import DescriptionSplit from '../../../../components/Description/description-split';
import UnorderedList from '../../../../components/List/unordered-list';
import Link from '../../../../components/Links/link';
import Button from '../../../../components/Buttons/button';
import { useNavigate } from 'react-router-dom';
import useIntersectionObserver from '../../../../utils/hooks/intersection-observer';
import H4 from '../../../../components/Titles/h4';

const SectionMap = ({ map }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function navigateReservation() {
        navigate('/contact');
    }

    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const titleRef2 = useRef(null);
    const descriptionRef2 = useRef(null);
    const listRef = useRef(null);
    const description2Ref = useRef(null);
    const buttonRef = useRef(null);

    useIntersectionObserver(
        [titleRef, descriptionRef, titleRef2, descriptionRef2, listRef, description2Ref, buttonRef],
        ["animate-fade-in-right", "animate-fade-in-up", "animate-fade-in-up", "animate-fade-in-up", "animate-fade-in-up", "animate-fade-in-up", "animate-fade-in-up"]
    );

    return (
        <div className="mt-12 lg:mt-24 flex w-10/12 m-auto justify-between flex-col-reverse lg:flex-row">
            <div className="lg:w-5/12 mt-8 m-auto">
                <div className="max-w-full">
                    <FullWidthImage url={map} alt={t('home.sectionMap.altImage')} />
                </div>

                <div className="text-center block lg:hidden lg:text-left mt-10">
                    <Link link="contact" disableHoverEffect={true}>
                        <Button text={t('home.sectionMap.button')} onClick={navigateReservation} />
                    </Link>
                </div>
            </div>
            <div className="lg:w-7/12 lg:p-6 lg:pl-24 lg:pt-12 lg:pr-12">
                <div ref={titleRef} className='opacity-0'>
                    <H2 title={t('home.sectionMap.title')} />
                </div>
                <div ref={descriptionRef} className="opacity-0 my-6">
                    <DescriptionSplit description={t('home.sectionMap.description')} />
                </div>

                <div ref={titleRef2} className='opacity-0'>
                    <H4 title={t('home.sectionMap.title2')} theme='text-description' fontWeight='bold' className='opacity-60' />
                </div>
                <div ref={descriptionRef2} className="opacity-0 mb-6">
                    <DescriptionSplit description={t('home.sectionMap.description2')} />
                </div>

                <div ref={listRef} className="opacity-0 lg:pl-8">
                    <UnorderedList items={t('home.sectionMap.list', { returnObjects: true })} />
                </div>

                <div ref={description2Ref} className="opacity-0 my-6">
                    <DescriptionSplit description={t('home.sectionMap.descriptionOrganizationOfYourStay')} />
                </div>

                <div ref={buttonRef} className="opacity-0 text-center hidden lg:block lg:text-left mt-10">
                    <Link link="contact" disableHoverEffect={true}>
                        <Button text={t('home.sectionMap.button')} onClick={navigateReservation} />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SectionMap;
